import { defineStore } from 'pinia';
import { useApiUtilStore } from '@/stores/apiutil-st';
import { VueOfflineStorage } from 'vue-offline';
import { getFilteredData } from './Helper/getFilteredData';
import jwtdecode from 'jwt-decode';
export const useDbStore = defineStore({
  id: 'db',
  persist: true,
  state: () => ({
    isFetchingCustomer: false,
    customer: null,
    brands: [],
    popular_brands: [],
    popular_branded_brands: [],
    categories: [],
    filter_options: [], //views
    models: [],
    lifecycles: [],
    documentTypes: [],
    featured_categories: [],
  }),
  getters: {
    marketingRegion: (state) => {
      if (
        state.customer &&
        state.customer.marketing_region_id ==
          process.env.VUE_APP_US_MARKETING_REGION
      ) {
        return 'us';
      } else if (
        state.customer &&
        state.customer.marketing_region_id ==
          process.env.VUE_APP_EU_MARKETING_REGION
      ) {
        return 'eu';
      } else if (
        state.customer &&
        state.customer.marketing_region_id ==
          process.env.VUE_APP_ZH_MARKETING_REGION
      ) {
        return 'zh';
      }
      return 'us';
    },
    isMedtenCustomer: (state) => {
      if (!state.customer) {
        return true;
      }
      const MedtenCustomers = [
        Number(process.env.VUE_APP_MEDTEN_US_CUSTOMER_ID),
        Number(process.env.VUE_APP_MEDTEN_EU_CUSTOMER_ID),
        Number(process.env.VUE_APP_MEDTEN_ZH_CUSTOMER_ID),
      ];
      return MedtenCustomers.includes(state.customer.id);
    },
    primaryColor: (state) => {
      if (state.customer && state.customer['primary_color']) {
        return {
          color: state.customer['primary_color'] + ' !important',
        };
      }
      return {
        color: '#000000' + ' !important',
      };
    },
    secondaryColor: (state) => {
      if (state.customer && state.customer['secondary_color']) {
        return {
          color: state.customer['secondary_color'] + ' !important',
        };
      }
      return {
        color: '#38BCD9' + ' !important',
      };
    },
    primaryBgColor: (state) => {
      
      if (state.customer && state.customer['primary_color']) {
        return {
          'background-color': state.customer['primary_color'] + ' !important',
          'border-color': state.customer['primary_color'] + ' !important',
        };
      }
      return {
        'background-color': '#38BCD9' + ' !important',
        'border-color': '#38BCD9' + ' !important',
      };
    },
    secondaryBgColor: (state) => {
      if (state.customer && state.customer['secondary_color']) {
        return {
          background: state.customer['secondary_color'] + ' !important',
          'border-color': state.customer['secondary_color'] + ' !important',
        };
      }
      return {
        background: '#38BCD9' + ' !important',
        'border-color': '#38BCD9' + ' !important',
      };
    },
    primaryBorder: (state) => {
      if (state.customer && state.customer['primary_color']) {
        return {
          'border-color': state.customer['primary_color'] + ' !important',
        };
      }
      return {
        'border-color': '#38BCD9' + ' !important',
      };
    },
    secondaryBorder: (state) => {
      if (state.customer && state.customer['secondary_color']) {
        return {
          'border-color': state.customer['secondary_color'] + ' !important',
        };
      }
      return {
        'border-color': '#38BCD9' + ' !important',
      };
    },
    primaryBgColorWithoutBorder: (state) => {
      if (state.customer && state.customer['primary_color']) {
        return {
          'background-color': state.customer['primary_color'] + ' !important',
        };
      }
      return {
        'background-color': '#38BCD9' + ' !important',
      };
    },
    secondaryBgColorWithoutBorder: (state) => {
      if (state.customer && state.customer['secondary_color']) {
        return {
          background: state.customer['secondary_color'] + ' !important',
        };
      }
      return {
        background: '#38BCD9' + ' !important',
      };
    },
    primaryColor: (state) => {
      if (state.customer && state.customer['primary_color']) {
        return {
          color: state.customer['primary_color'] + ' !important',
        };
      }
      return {
        color: '#38BCD9' + ' !important',
      };
    },
  },
  actions: {
    brandFor(id) {
      return this.brands.find((b) => b.id == id);
    },
    categoryFor(id) {
      const result = this.categories.find((c) => c.id == id);
      return result || {};
    },
    subcategoriesOf(id) {
      return this.categories.filter((c) => c.parent_id == id);
    },
    /**
     * Checks local storage for the customer jwt and sets the store from the JWT if it exists. Otherwise it
     * calls loadCustomer to determine the identity of the customer from the website origin.
     */
    async hydrateCustomer() {
      // Existing valid customer JWT?
      try {
        //try to hydrate
        let custjwt = VueOfflineStorage.get('mtcustomer');
        if (!custjwt) throw new Error('No stored customer.');
        let parsed = jwtdecode(custjwt); //throws error if invalid
        if (parsed && parsed.customer) this.setCustomer(parsed.customer);
      } catch (err) {
        try {
          // "Silent" login. This must come before any customer-dependent api calls.
          await this.loadCustomer();
        } catch (err) {
          console.error('Error determining customer identity. ' + err.message);
          console.error(err);
        }
      }
    },
    async loadLifecycles() {
      let api = useApiUtilStore();
      let r = await api.callApi({ method: 'GET', url: '/lifecycles' }, false);
      if (r.success) this.lifecycles = r.data.lifecycles;
    },
    async loadDocumentTypes() {
      let api = useApiUtilStore();
      let r = await api.callApi({ method: 'GET', url: '/document_types' }, false);
      if (r.success) this.documentTypes = r.data.document_types;
    },
    
    async loadBrands() {
      let api = useApiUtilStore();
      let r = await api.callApi({ method: 'GET', url: '/brands' }, false);
      if (r.success) this.brands = r.data.brands;
    },
    async loadPopularBrands() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: '/brands?is_popular=1' },
        false
      );
      if (r.success) this.popular_brands = r.data.brands;
      // console.log('this.popular_brands', this.popular_brands)
    },
    async loadPopularBrandedBrands() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: '/brands?is_popular_branded=1' },
        false
      );
      if (r.success) this.popular_branded_brands = r.data.brands;
      console.log('this.popular_branded_brands', this.popular_branded_brands);
    },
    async loadCategories() {
      console.log('LOADCATE');
      let api = useApiUtilStore();
      let r = await api.callApi({ method: 'GET', url: '/categories' }, false);
      if (r.success) {
        let categories = r.data.category_views.filter((item) => {
          if (item.publish === true) {
            return true;
          }
        });
        this.categories = categories.sort((a, b) => {
          if (a.name_en > b.name_en) return 1;
          else if (a.name_en < b.name_en) return -1;
          return 0;
        });
      }
    },
    async loadCustomer() {
      if (this.customer || this.isFetchingCustomer) {
        // return
      }
      this.isFetchingCustomer = true;
      try {
        let api = useApiUtilStore();
        let r = await api.callApi({
          method: 'POST',
          url: '/customer-identity',
        });
        if (r && r.data.token) {
          VueOfflineStorage.set('mtcustomer', r.data.token); //customer-encoded JWT to be submited with customer-dependent APIs
          let parsed = jwtdecode(r.data.token); //throws error if invalid
          this.customer = parsed.customer;
          // console.debug(`Set customer identity: ${JSON.stringify(parsed.customer)}`)
        }
        this.isFetchingCustomer = false;
      } catch (ex) {
        console.error(ex);
        this.isFetchingCustomer = false;
      }
    },
    isParts(id) {
      let parent_id = id;
      let category = this.categories.find((c) => c.id == id);

      if (!category) {
        return false;
      }
      parent_id = category['parent_id'] ? category['parent_id'] : parent_id;
      while (parent_id != 1 && parent_id != 2 && category) {
        category = this.categories.find((c) => c.id == parent_id);
        parent_id = category['parent_id'];
      }
      if (parent_id == 2) {
        return true;
      } else {
        return false;
      }
    },
    async loadFeaturedCategories() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: '/categories?featured=1' },
        false
      );
      if (r.success) {
        if (!this.categories || this.categories.length === 0) {
          this.featured_categories = r.data.category_views;
        } else {
          const allFeaturedCategories = r.data.category_views;
          const filteredData = getFilteredData(
            allFeaturedCategories,
            this.customer,
            this.isMedtenCustomer,
            this.isParts,
            'id'
          );
          this.featured_categories = filteredData;
        }
      }
    },
    async loadFilterOptions(parms) {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: '/filter_option_views', parms },
        false
      );
      if (r.success) {
        this.filter_options = r.data.filter_option_views;
      }
    },
    async loadModels() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: '/equipment/models' },
        false
      );
      if (r.success) {
        this.models = r.data;
        // this.models = r.data.filter(model => model.model);
      }
    },
    setCustomer(c) {
      this.customer = c;
    },
  },
});
