import { mapState, mapActions, mapGetters } from 'pinia';
import { useDbStore } from '@/stores/db-st';
export default {
  computed: {
    ...mapState(useDbStore, ['customer',]),
    ...mapGetters(useDbStore, ['isMedtenCustomer',]),
  },
  methods: {
    getProductStock(product){
      let stock = 0;
      if(!product ) return 0;
      if(this.customer && this.customer.marketing_region_id){
        switch(Number(this.customer.marketing_region_id)){
          case Number(process.env.VUE_APP_US_MARKETING_REGION):
            stock = product.stock_usa;
            // console.log(stock, process.env.VUE_APP_US_MARKETING_REGION, this.customer.marketing_region_id)
            break;
          case Number(process.env.VUE_APP_EU_MARKETING_REGION):
            stock = product.stock_eu;
            // console.log(stock, process.env.VUE_APP_EU_MARKETING_REGION, this.customer.marketing_region_id)
            break;
          case Number(process.env.VUE_APP_ZH_MARKETING_REGION):
            stock = product.stock_cn;
            // console.log(stock, process.env.VUE_APP_ZH_MARKETING_REGION, this.customer.marketing_region_id)
            break;
          default:
            stock = product.stock_us;
        }
      } else {
        stock = product.stock_usa;
      }
      return stock;
    },

    /** product stock in a comma-delimited string, ordered according to customer marketing region */
    // getProductStockInfo(product) {
    //   const labels = {
    //     excessStock: 'In stock: 100+',
    //     inStock: 'In stock:',
    //     outOfStock: 'Out of Stock',
    //   }
    //   let qty = null;

    //   let marketing_region_id =  this.customer ? this.customer.marketing_region_id : 0;
    //   switch(marketing_region_id){
    //     case Number(process.env.VUE_APP_EU_MARKETING_REGION):
    //       qty = product.stock_eu;
    //       break;
    //     case Number(process.env.VUE_APP_ZH_MARKETING_REGION):
    //       qty = product.stock_zh;
    //       break;
    //     case Number(process.env.VUE_APP_US_MARKETING_REGION):
    //     default:
    //       qty = product.stock_usa;
    //       break;
    //   }
    //   if (!qty) {
    //     return labels.outOfStock;
    //   } 
    //   return qty > 100 
    //     ? labels.excessStock
    //     : `${labels.inStock} ${qty}`
    // },

    getProductStockInfo(product){
      let stock_info = [];
      let us_stock = { quantity: product.stock_usa, label:   this.$t('search.label.us') };
      let eu_stock = { quantity: product.stock_eu,  label:  this.$t('search.label.eu') };
      let cn_stock = { quantity: product.stock_cn, label:  this.$t('search.label.cn') };
      //US: US, EU, ZH
      //EU: EU, US, ZH
      //ZH: ZH, US, EU

      let marketing_region_id =  this.customer ? this.customer.marketing_region_id : 0;
      switch(marketing_region_id){
        case Number(process.env.VUE_APP_EU_MARKETING_REGION):
          stock_info.push(eu_stock);
          stock_info.push(us_stock);
          stock_info.push(cn_stock);
          break;
        case Number(process.env.VUE_APP_ZH_MARKETING_REGION):
          stock_info.push(cn_stock);
          stock_info.push(us_stock);
          stock_info.push(eu_stock);
          break;
        case Number(process.env.VUE_APP_US_MARKETING_REGION):
        default:
          stock_info.push(us_stock);
          stock_info.push(eu_stock);
          stock_info.push(cn_stock);
          break;
      }
      if(this.isMedtenCustomer){
        return stock_info.map(p => {
          let amt = p.quantity > 100 
            ? '100+'
            : p.quantity > 0 
              ? p.quantity
              : this.$t('search.label.out_of_stock')
          return `${p.label}=${amt}`;
        }).join(', ');
      }else {
        let amt = stock_info[0].quantity > 100 
            ? 'In stock: 100+'
            : stock_info[0].quantity > 0 
              ? `In stock: ${stock_info[0].quantity}`
              : this.$t('search.label.out_of_stock') ;
        return amt;
      }
    },

    isInStockInfoRegion(product){
      let stock_info = null;
      let us_stock = { quantity: product.stock_usa, label: 'US' };
      let eu_stock = { quantity: product.stock_eu,  label: 'EU' };
      let cn_stock = { quantity: product.stock_cn, label: 'CN' };
      //US: US, EU, ZH
      //EU: EU, US, ZH
      //ZH: ZH, US, EU

      let marketing_region_id =  this.customer ? this.customer.marketing_region_id : 0;
      switch(marketing_region_id){
        case Number(process.env.VUE_APP_EU_MARKETING_REGION):
          stock_info = eu_stock;
          break;
        case Number(process.env.VUE_APP_ZH_MARKETING_REGION):
          stock_info = cn_stock;
          break;
        case Number(process.env.VUE_APP_US_MARKETING_REGION):
        default:
          stock_info = us_stock;
          break;
      }
      return stock_info;
    }
  },
};
