import { defineStore } from "pinia";
import { VueOfflineStorage } from 'vue-offline';
export const useCartStore = defineStore({
  id: "cart",
  persist: true,
  state: () => ({
    cart_items: [],
    cart_contact: [],
  }),
  getters: {
  },
  actions: {
    createCartItem({id, image_url, sku, oem, name_en,name_zh, description_en,description_zh, price, quantity}){
      this.cart_items.push({
        id,
        sku,
        oem,
        name_en,
        name_zh,
        image_url,
        description_en,
        description_zh,
        price,
        quantity: quantity || 0,
      });
      VueOfflineStorage.set('cart_items', this.cart_items);
    },
    emptyCart(){
      this.cart_items = [];
      VueOfflineStorage.set('cart_items', this.cart_items);
    },
    findCartItem(product_id){
      return this.cart_items.find(i => i.id==product_id );
    },
    hydrateCart(){
      let ol_cart_items = VueOfflineStorage.get("cart_items");
      if(ol_cart_items) this.cart_items = ol_cart_items;
      let ol_cart_contact = VueOfflineStorage.get("cart_contact");
      if(ol_cart_contact) this.cart_contact = ol_cart_contact;
    },
    loadCartItems(){
      this.cart_items = VueOfflineStorage.get('cart_items');
      if(!this.cart_items) this.cart_items = [];
    },
    removeCartItem(product_id){
      let idx = this.cart_items.findIndex(i=>i.id==product_id);
      if(idx>=0){
        this.cart_items.splice(idx, 1);
        VueOfflineStorage.set('cart_items', this.cart_items);
      }
    },
    setCartContact(cart_contact){ 
      this.cart_contact = cart_contact;
      VueOfflineStorage.set("cart_contact", this.cart_contact);
    },
    updateCartItem(id, quantity){
      let idx = this.cart_items.findIndex(i=>i.id==id);
      if(idx<0) return;
      this.cart_items[idx].quantity = Number(this.cart_items[idx].quantity) + Number(quantity);
      // let item = this.cart_items[idx];
      // item.quantity = Number(quantity) + Number(item.quantity);
      // this.cart_items.splice(idx, 1, item); //reactivity detects splice
      VueOfflineStorage.set('cart_items', this.cart_items);
    }
  },
});
