import { defineStore } from 'pinia';
import { useApiUtilStore } from '@/stores/apiutil-st';
import { useDbStore } from '@/stores/db-st';
import { useBaseStore } from './base-st';
// import $t from '@/lib/i18n';
import { VueOfflineStorage } from 'vue-offline';
import { getFilteredData } from './Helper/getFilteredData';

export const useProductSearchStore = defineStore({
  id: 'product-search',
  persist: true,
  state: () => ({
    brands: [],
    categories: [],
    models: [],

    // search-criteria/results
    selected_brand_ids: [],
    selected_category_ids: [],
    product_search_model: null,
    product_search_term: '',
    product_search_results: [],
    product_search_total: 0,
    product_search_offset: 0,
    product_search_limit: 5000,
    product_images: [],
    popular_items: [],
    featured_products: [],
    new_arrivals: [],
  }),
  getters: {
    brand_ids_in_results() {
      console.log(
        this.product_search_results,
        this.product_search_results.map((c) => c.oem_brand_id)
      );
      return this.product_search_results
        .map((c) => c.oem_brand_id)
        .reduce((arr, id) => {
          if (id) arr.push(id);
          return arr;
        }, []);
    },

    category_ids_in_results() {
      return this.product_search_results
        .map((c) => c.category_id)
        .reduce((arr, id) => {
          if (id) arr.push(id);
          return arr;
        }, []);
    },

    brands_in_scope() {
      if (this.product_search_total === 0) {
        return [];
      } else {
        return useDbStore().brands.filter((b) =>
          this.brand_ids_in_results.includes(b.id)
        );
      }
    },

    categories_in_scope() {
      if (this.product_search_total === 0) {
        return [];
      } else {
        return useDbStore().categories.filter((cat) =>
          this.category_ids_in_results.includes(cat.id)
        );
      }
    },
    // models_in_scope(){
    //   if(this.product_search_total === 0){
    //     return [];
    //   } else {
    //     // console.log('models_in_scope', useDbStore().models.filter( m=>this.models_in_results.includes(m.model) ))
    //     return useDbStore().models.filter( m=>this.models_in_results.includes(m.model));
    //   }
    // },
    models_in_results() {
      let models = [];
      this.product_search_results.forEach((p) => {
        if (p.models) {
          p.models.split('|').forEach((pmodel) => {
            if (!models.includes(pmodel)) {
              models.push(pmodel);
            }
          });
        }
      });
      // console.log('models', useDbStore().models.filter( m=> models.includes(m.model)));
      return useDbStore().models.filter((m) => models.includes(m.model));
    },

    filter_option_ids_in_results() {
      let pfoids = [];
      this.product_search_results.forEach((p) => {
        if (p.filter_option_ids) {
          p.filter_option_ids.split('|').forEach((pfoid) => {
            if (!pfoids.includes(pfoid)) {
              pfoids.push(pfoid);
            }
          });
        }
      });
      return pfoids;
    },

    search_criteria_provided() {
      return (
        this.selected_brand_ids.length > 0 ||
        this.selected_category_ids.length > 0 ||
        this.product_search_model ||
        this.product_search_term
      );
    },
  },
  actions: {
    /** only within search results. */
    productsForBrand(brand_id) {
      if (!this.product_search_results) return [];
      if (this.product_search_total > 0) {
        return this.product_search_results.filter(
          (p) => p.oem_brand_id == brand_id
        );
      }
    },

    resetSearchOptions() {
      this.product_search_term = '';
      this.product_search_model = '';
      this.selected_brand_ids = [];
      this.selected_category_ids = [];
      console.log('resetSearchOptions');
    },

    productsWithinCategory(category_id) {
      if (this.product_search_total === 0) {
        return [];
      } else if (this.product_search_total > 0) {
        return this.product_search_results.filter(
          (p) => p.category_id == category_id
        );
      }
    },
    /**
     * Removes a criterion from current search state. Does NOT invoke a search.
     * @param {string} what term|brand|category|equipment
     * @param {*} id when what is one of brand|category|equipment, provide the id to remove
     */
    removeCriterion(what, id) {
      if (what === 'term') {
        this.product_search_term = '';
      } else if (what === 'model') {
        this.product_search_model = '';
      } else {
        let idx = -1;
        let arr = null;
        switch (what) {
          case 'brand':
            arr = this.selected_brand_ids;
            break;
          case 'category':
            arr = this.selected_category_ids;
            break;
        }
        if (arr) {
          idx = arr.findIndex((x) => x == id);
          if (idx >= 0) {
            arr.splice(idx, 1);
          }
        }
      }
    },

    removeAllCriterion(what, id) {
      this.product_search_term = '';
      this.product_search_model = '';
      this.selected_brand_ids = [];
      this.selected_category_ids = [];
    },

    async searchProductsForDevice({ brand_id, model }) {
      // this.$reset();
      if (!model && !brand_id) return;
      let criteria = {};
      if (brand_id) {
        criteria.brand_ids = [brand_id];
      }
      if (model) {
        criteria.product_search_model = model;
      }
      await this.searchProducts(criteria);
    },

    /**
     * Performs a quick search for products using the search term. SKU, OEM, and oem references are searched using a wildcard match.
     * @param {string} product_search_term
     * @param {string} product_search_model optional
     * @param {array} brand_ids optional
     * @param {array} category_ids optional
     * @param {number} limit optional
     * @param {number} offset optional
     */
    async searchProducts({
      product_search_term,
      brand_ids,
      product_search_model,
      category_ids,
      limit,
      offset,
    }) {
      // record state if it was given...
      if (
        typeof product_search_term !== 'undefined' &&
        product_search_term !== null
      ) {
        this.product_search_term = product_search_term;
      } else {
        this.product_search_term = '';
      }
      if (
        typeof product_search_model !== 'undefined' &&
        product_search_model !== null
      ) {
        this.product_search_model = product_search_model;
      } else {
        this.product_search_model = '';
      }
      if (brand_ids) {
        this.selected_brand_ids = brand_ids;
      } else {
        this.selected_brand_ids = [];
      }
      if (category_ids) {
        this.selected_category_ids = category_ids;
      } else {
        this.selected_category_ids = [];
      }
      if (Number.isFinite(limit)) this.product_search_limit = limit;
      if (Number.isFinite(offset)) this.product_search_offset = offset;

      let parms = { with: 'images' };
      parms.search_term = this.product_search_term;
      parms.model = this.product_search_model;
      parms.brand_ids =
        this.selected_brand_ids.length === 0
          ? undefined
          : this.selected_brand_ids.join('|');
      parms.category_ids =
        this.selected_category_ids.length === 0
          ? undefined
          : this.selected_category_ids.join('|');
      parms.limit = this.product_search_limit;
      parms.offset = this.product_search_offset;

      if (!this.search_criteria_provided) {
        useBaseStore().$patch({
          message: this.$t(`global.verbiage.provide_search_criteria`),
        });
        return;
      }

      let api = useApiUtilStore();
      let r = await api.callApi({
        method: 'GET',
        url: '/products/quicksearch',
        parms,
        jwt: VueOfflineStorage.get('mtcustomer'),
      });
      if (r.success) {
        // this.product_search_results = this.groupByOEM(r.data.products, 'oem');
        let product_results = r.data.products.filter(
          (item) => item.ad_url == ''
        );
        const filteredData = getFilteredData(
          product_results,
          useDbStore().customer,
          useDbStore().isMedtenCustomer,
          this.isParts
        );
        // if (!useDbStore().isMedtenCustomer) {
        //   product_results = product_results.filter(
        //     (item) => !this.isParts(item.category_id)
        //   );
        // }
        this.product_search_results = this.groupByOEM(filteredData, 'oem');
        this.product_search_total = this.product_search_results.length;
        if (r.data.product_images) this.product_images = r.data.product_images;
      }
    },
    isParts(id) {
      let parent_id = id;
      let category = useDbStore().categories.find((c) => c.id == id);

      if (!category) {
        return false;
      }
      parent_id = category['parent_id'] ? category['parent_id'] : parent_id;
      while (parent_id != 1 && parent_id != 2 && category) {
        category = useDbStore().categories.find((c) => c.id == parent_id);
        parent_id = category['parent_id'];
      }
      if (parent_id == 2) {
        return true;
      } else {
        return false;
      }
    },
    async refreshProductSearch() {
      // console
      let api = useApiUtilStore();
      let r = await api.callApi({
        method: 'GET',
        url: '/products/quicksearch?with=images',
        jwt: VueOfflineStorage.get('mtcustomer'),
      });
      if (r.success) {
        // this.product_search_results = this.groupByOEM(r.data.products, 'oem');
        let product_results = r.data.products.filter(
          (item) => item.ad_url == ''
        );
        if (!useDbStore().isMedtenCustomer) {
          product_results = product_results.filter(
            (item) => !this.isParts(item.category_id)
          );
        }
        this.product_search_results = this.groupByOEM(product_results, 'oem');
        this.product_search_total = this.product_search_results.length;
        if (r.data.product_images) this.product_images = r.data.product_images;
      }
    },

    toggleSelectedBrand(brand_id) {
      if (this.selected_brand_ids.includes(brand_id)) {
        let i = this.selected_brand_ids.findIndex((id) => id == brand_id);
        if (i >= 0) this.selected_brand_ids.splice(i, 1);
      } else {
        this.selected_brand_ids.push(brand_id);
      }
      this.searchProducts({});
    },

    toggleSelectedCategory(category_id) {
      if (this.selected_category_ids.includes(category_id)) {
        let i = this.selected_category_ids.findIndex((id) => id == category_id);
        if (i >= 0) this.selected_category_ids.splice(i, 1);
      } else {
        this.selected_category_ids.push(category_id);
      }
      this.searchProducts({});
    },

    async loadPopularProduct() {
      let jwt = VueOfflineStorage.get('mtcustomer');
      let api = useApiUtilStore();
      let r = await api.callApi(
        {
          method: 'GET',
          url: '/products/quicksearch?popular=1&with=images',
          jwt,
        },
        false
      );
      let product_images = null;
      if (r.success) {
        const filteredData = getFilteredData(
          r.data.products,
          useDbStore().customer,
          useDbStore().isMedtenCustomer,
          this.isParts
        );
        this.popular_items = filteredData;
        product_images = r.data.product_images;
      }
      if (product_images) {
        this.popular_items.forEach((product) => {
          let item = product_images.find(
            (p) => p.product_id == product.id && p.image_type == 'Full Product'
          );
          if (item) {
            product['image_url'] = item.image_link;
          } else {
            item = product_images.find(
              (p) =>
                p.product_id == product.id &&
                p.image_type == 'Full Product - PS'
            );
            if (item) {
              product['image_url'] = item.image_link;
            } else {
              item = product_images.find((p) => p.product_id == product.id);
              if (item) {
                product['image_url'] = item.image_link;
              }
            }
          }
        });
      }
      // console.log('popular_items', this.popular_items);
    },
    groupByOEM(data, property) {
      let groupArray = data.reduce((acc, obj) => {
        // const key = obj[property];
        const key = obj.id;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      let final = [];
      for (let groupKey in groupArray) {
        if (
          groupKey == '' &&
          groupArray[groupKey] &&
          groupArray[groupKey].length
        ) {
          groupArray[groupKey].forEach((item) => {
            item['options'] = [];
            final.push(item);
          });
        } else if (
          groupKey != '' &&
          groupArray[groupKey] &&
          groupArray[groupKey].length
        ) {
          let tempItem;
          groupArray[groupKey].forEach((item, index) => {
            if (index == 0) {
              tempItem = item;
              tempItem['options'] = [];
            } else {
              tempItem['options'].push(item);
            }
          });
          final.push(tempItem);
        }
      }
      const uniqueProductIds = new Set();
      const uniqResults = final.filter((result) => {
        if (!uniqueProductIds.has(result.id)) {
          uniqueProductIds.add(result.id);
          return true;
        }
        return false;
      });
      const uniqData = uniqResults.map((item) => {
        return {
          ...item,
          compatibilityBrandIds:
            item.oemCompatibilityBrands?.map((oc) => oc.brand_id) || [],
        };
      });
      return uniqData;
    },
    async loadFeaturedProduct() {
      let jwt = VueOfflineStorage.get('mtcustomer');
      let api = useApiUtilStore();
      let r = await api.callApi(
        {
          method: 'GET',
          url: '/products/quicksearch?featured=1&with=images',
          jwt,
        },
        false
      );
      let product_images = null;
      if (r.success) {
        const filteredData = getFilteredData(
          r.data.products,
          useDbStore().customer,
          useDbStore().isMedtenCustomer,
          this.isParts
        );
        this.featured_products = filteredData;
        product_images = r.data.product_images;
      }

      if (product_images) {
        this.featured_products.forEach((product) => {
          let item = product_images.find(
            (p) => p.product_id == product.id && p.image_type == 'Full Product'
          );
          if (item) {
            product['image_url'] = item.image_link;
          } else {
            item = product_images.find(
              (p) =>
                p.product_id == product.id &&
                p.image_type == 'Full Product - PS'
            );
            if (item) {
              product['image_url'] = item.image_link;
            } else {
              item = product_images.find((p) => p.product_id == product.id);
              if (item) {
                product['image_url'] = item.image_link;
              }
            }
          }
        });
      }
      // console.log('featured_products', this.featured_products);
    },

    async newArrivalProductSearch(translatedNewArrival) {
      // let todayDate = new Date();
      // todayDate.setDate(todayDate.getDate() - 60);
      // const yyyy = todayDate.getFullYear();
      // let mm = todayDate.getMonth() + 1; // Months start at 0!
      // if(mm<10) mm = "0"+mm;
      // let dd = todayDate.getDate();
      // if(dd<10) dd = "0"+dd;
      // let dateStr = yyyy+"-"+mm+"-"+dd;
      this.product_search_model = translatedNewArrival;
      let parms = { with: 'images' };
      // parms.created_since = dateStr;
      let jwt = VueOfflineStorage.get('mtcustomer');
      let api = useApiUtilStore();
      let r = await api.callApi({
        method: 'GET',
        url: `/products/quicksearch?new_arrival=1`,
        parms,
        jwt,
      });
      let product_images = null;
      if (r.success) {
        let product_results = r.data.products.filter(
          (item) => item.ad_url == ''
        );
        if (!useDbStore().isMedtenCustomer) {
          product_results = product_results.filter(
            (item) => !this.isParts(item.category_id)
          );
        }
        this.product_search_results = this.groupByOEM(product_results, 'oem');
        this.product_search_total = this.product_search_results.length;
        if (r.data.product_images) this.product_images = r.data.product_images;
      }
      // console.log('featured_products', this.featured_products);
    },

    async loadNewArrivals() {
      let parms = { with: 'images' };
      let jwt = VueOfflineStorage.get('mtcustomer');
      let api = useApiUtilStore();
      let r = await api.callApi(
        {
          method: 'GET',
          url: `/products/quicksearch?new_arrival=1`,
          parms,
          jwt,
        },
        false
      );
      let product_images = null;
      if (r.success) {
        const filteredData = getFilteredData(
          r.data.products,
          useDbStore().customer,
          useDbStore().isMedtenCustomer,
          this.isParts
        );
        const uniqueProductIds = new Set(); // To store unique product IDs
        this.new_arrivals = filteredData.filter((product) => {
          if (!uniqueProductIds.has(product.id)) {
            uniqueProductIds.add(product.id);
            return true; // Include the product if it's not a duplicate
          }
          return false; // Exclude the product if it's a duplicate
        });
        product_images = r.data.product_images;
      }

      if (product_images) {
        this.new_arrivals.forEach((product) => {
          let item = product_images.find(
            (p) => p.product_id == product.id && p.image_type == 'Full Product'
          );
          if (item) {
            product['image_url'] = item.image_link;
          } else {
            item = product_images.find(
              (p) =>
                p.product_id == product.id &&
                p.image_type == 'Full Product - PS'
            );
            if (item) {
              product['image_url'] = item.image_link;
            } else {
              item = product_images.find((p) => p.product_id == product.id);
              if (item) {
                product['image_url'] = item.image_link;
              }
            }
          }
        });
      }
    },
  },
});
