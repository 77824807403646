import { defineStore } from "pinia";
import { useBaseStore } from './base-st';
import { useApiUtilStore } from './apiutil-st';
import { VueOfflineStorage } from 'vue-offline';

import jwtdecode from 'jwt-decode';
export const ADMIN_ROLE = 'admin';
export const BUYER_ROLE = 'buyer';//the default role assigned to buyers logging in the catalog
export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    org_principal: null,
    auth_error_count: 0,
    jwt: null,
    password: null,
    username: null,
    user_principal: null,
  }),
  getters: {
    is_admin(){
      return this.user_principal && this.user_principal.roles && this.user_principal.roles.includes(ADMIN_ROLE);
    },
    is_buyer(){
      return this.user_principal && this.user_principal.roles && this.user_principal.roles.includes(BUYER_ROLE);
    },
    isLoggedIn(){
      if(!this.jwt) return false;
      try{
        let parsed = jwtdecode(this.jwt);
        let now = Math.floor(Date.now()/1000);
        return (parsed.exp > now);
      } catch(err) {
        return false;
      }
    },
  },
  actions: {
    /**
     * Parses and SETS the given JWT. The store is hydrated if it is valid.
     * @param { string } t raw token 
     */
    parseNewJwt(t){
      console.debug(`Parsing jwt...`)
      let parsed = jwtdecode(t);//throws error if invalid
      this.jwt = t;
      VueOfflineStorage.set('jwt', t);
    
      this.hydrate();
    },
    /**
     * Syncronous. Hydrates auth settings from a stored JWT.
     */
    hydrate(){
      try{
        this.jwt = VueOfflineStorage.get('jwt');
        if(!this.jwt) return;
        let parsed = jwtdecode(this.jwt);//throws error if invalid

        console.debug(`Setting auth state into store...`);

        if(parsed.user){
          this.user_principal = parsed.user;
          VueOfflineStorage.set('upr', this.user_principal);
        }
        
        if(parsed.org){
          this.org_principal = parsed.org;
          VueOfflineStorage.set('opr', this.org_principal);
        }

        return parsed;
  
      }catch(err){
        this.incrementErrorCount();
        console.error(err);
        return null;
      }

    },
    incrementErrorCount(){ this.auth_error_count++; },
    /** 
     * Processes the login. 
     * Logging in resets the store.
     * A successful login receives a JWT which is parsed and used to (re-)hydrate this store. 
     * An unsuccessful login results in no principal information being set. An error message is available in the baseStore.
    */
    async login(){
      const apiStore = useApiUtilStore();
      const baseStore = useBaseStore();
      try{
        baseStore.message = "Logging in...";
        
        if(!this.username || !this.password){
          baseStore.clearMessageAndErrors();
          baseStore.error = "Credentials not provided."
          this.$reset();
          return;
        }

        let {data:loginResponse} = await apiStore.callApi({method: 'post', url: `${process.env.VUE_APP_API_AUTH_BASE_URL}/login`, body: {username: this.username, password: this.password}});
        this.$reset();
        
        if(loginResponse && loginResponse.token){
          console.debug(`Checking token...\n${loginResponse.token}`);
          this.parseNewJwt(loginResponse.token);
          this.jwt = loginResponse.token;
          console.debug(`Saving locally...\n${loginResponse.token}`);
          VueOfflineStorage.set('jwt', this.jwt);
          
        } else {
          baseStore.error = "Invalid credentials.";

        }
      }catch(ex){
        console.error(ex);
        if (ex instanceof ApiNotAvailableError) {
          baseStore.global_error = ex.message;
         
        } else {
          baseStore.clearMessageAndErrors(); 
          
          if (ex instanceof ApiError) throw ex;
          baseStore.error = "Invalid credentials.";
        }
      } finally {
        this.password = null;//never keep plaintext password around
      }

    },
    logout(){
      VueOfflineStorage.set('jwt', null);
      VueOfflineStorage.set('upr', null);
      VueOfflineStorage.set('opr', null);

      this.$reset();
    },
    
    async forgotPassword(email) {
      const apiStore = useApiUtilStore();
      const baseStore = useBaseStore();
      try{

        baseStore.clearMessageAndErrors();
        return await apiStore.callApi({method: 'post', url: `${process.env.VUE_APP_API_AUTH_BASE_URL}/forgot-password`, body: { email } });
       
      }catch(err){
        console.error(err);
      }
    },

    /**
     * Invokes the forgot-password api which requests a password-reset email to be sent.
     * @param {string} email requesting email
     */
    async resetPassword({token, password}){
      const apiStore = useApiUtilStore();
      const baseStore = useBaseStore();
      try{

        baseStore.clearMessageAndErrors();
        return await apiStore.callApi({method: 'post', url: `${process.env.VUE_APP_API_AUTH_BASE_URL}/reset-password`, body: { token, password } });
       
      }catch(err){
        console.error(err);
      }

    }
  },
});
